import bgImg1 from '../images/background/bg2.png';
// import bgImg2 from '../images/background/bg-3.jpg';
// import bgImg3 from '../images/background/bg-4.jpg';
// import img1 from '../images/common/busd.png';

const dataSlider = [
    // {
    //     id: 1,
    //     title: 'BUSD Vault',
    //     desc : 'The vault is designed to have a least price such that every holder do not have to worry about the price',
    //     bgImg: bgImg1,
    //     // img : img1
    // },
    {
        id: 2,
        title: 'BUSD Vault',
        desc : 'The vault is designed to have a least price such that every holder do not have to worry about the price',
        bgImg: bgImg1,
        classAction: 'two'
    },
    // {
    //     id: 3,
    //     title: 'BUSD Vault',
    //     desc : 'The vault is designed to have a least price such that every holder do not have to worry about the price',
    //     bgImg: bgImg1,
    //     classAction: 'three'
    // },
    // {
    //     id: 4,
    //     title: 'BUSD Vault',
    //     desc : 'The vault is designed to have a least price such that every holder do not have to worry about the price',
    //     bgImg: bgImg1,
    //     // img : img1
    // },
    {
        id: 5,
        title: 'BUSD Vault',
        desc : 'The vault is designed to have a least price such that every holder do not have to worry about the price',
        bgImg: bgImg1,
        classAction: 'two'
    },

]

export default dataSlider;