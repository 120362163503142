
import img1 from '../images/logo/BSC.png'
import img2 from '../images/logo/pancakeswap.png'
import img3 from '../images/logo/chainLink.png'
import img4 from '../images/logo/tofu2.png'
import img5 from '../images/logo/cglogo.png'
import img6 from '../images/logo/cmclogo.png'
import img7 from '../images/logo/logo7.png'
import img8 from '../images/logo/logo8.png'
import img9 from '../images/logo/logo9.png'
import img10 from '../images/logo/logo10.png'
import img11 from '../images/logo/logo11.png'
import img12 from '../images/logo/logo12.png'
import img13 from '../images/logo/logo13.png'
import img14 from '../images/logo/logo14.png'
import img15 from '../images/logo/logo15.png'
import img16 from '../images/logo/logo16.png'
import img17 from '../images/logo/logo17.png'
import img18 from '../images/logo/logo18.png'
const dataPartner = [
    {
        img: img1,
    },
    {
        img: img2,
    },
    {
        img: img3,
    },
    {
        img: img4,
    },
    {
        img: img5,
    },
    {
        img: img6,
    },
    // {
    //     img: img7,
    // },
    // {
    //     img: img8,
    // },
    // {
    //     img: img9,
    // },
    // {
    //     img: img10,
    // },
    // {
    //     img: img11,
    // },
    // {
    //     img: img12,
    // },
    // {
    //     img: img13,
    // },
    // {
    //     img: img14,
    // },
    // {
    //     img: img15,
    // },
    // {
    //     img: img16,
    // },
    // {
    //     img: img17,
    // },
    // {
    //     img: img18,
    // },
]

export default dataPartner;